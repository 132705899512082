@import "./responsive";

::selection{
  background-color: rgba($brand-accent, 0.2);
}

html {
  font-family: $base-font-family;
  font-weight: $light-font-weight;
  overflow-x: hidden;
}

body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  background-color: $background-color;
  color: $body-text-color;
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $light-font-weight;
  font-size: $xlarge-font-size;
  padding: 0;
  margin: 0;
  margin-bottom: $spacing-small;
  color: $heading-text-color;
  line-height: 1.15em;
}

h3 {
  font-size: $large-font-size;
}
h4,
h5,
h6 {
  font-size: $base-font-size;
  font-weight: $bold-font-weight;
}

a,
a:hover,
a:active,
a:visited {
  color: $brand-accent;
  text-decoration: none;
  cursor: pointer;

  &.uncolored{
    color: black;
  }
}

p a,
h1 a,
h2 a,
h3 a {
  color: $brand-accent;
}

p {
  margin: 0 auto;
  margin-bottom: $spacing-small;
  line-height: $base-line-height;
}

p.large {
  font-size: $large-font-size * 0.975;
  font-weight: 300;
}

strong {
  font-weight: $bold-font-weight;
}

figure {
  margin: $spacing-medium 0;

  img {
    width: 100%;
  }

  figcaption {
    text-align: center;
    font-weight: $bold-font-weight;
    color: $gray-medium;
    font-size: $small-font-size;
  }
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

a.read-more {
  display: block;
  width: auto;
  // float: right;
  margin-top: $spacing-small;
  margin-bottom: $spacing-small;
  color: $brand-accent;
}

.youtube-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

section.checklist {
  h2 {
    font-size: $large-font-size;
  }
  ol {
    margin: $spacing-large 0;

    list-style: none;
    padding: 0;

    li {
      position: relative;
      margin-bottom: 1em;
      padding-left: 30px + $spacing-small;

      &:before {
        position: absolute;
        top: 0;
        left: 0;

        border: 1px solid $gray-medium;
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        float: left;
      }
    }
  }
}

a.show-more {
  font-size: $base-font-size;
  font-weight: $bold-font-weight;
  margin-top: $spacing-medium;
  width: auto;
  display: block;
  float: right;
  text-align: right;
}

.big-form {
  background-color: $gray-ultra-light;
  padding: $spacing-medium;
  box-sizing: border-box;

  label {
    display: block;
  }

  input {
    display: block;
    font-size: $large-font-size;
  }

  input[type="submit"] {
    float: right;
  }
}

.form-wrapper {
  margin-bottom: $spacing-small;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  box-sizing: border-box;
  padding: 0.3em;
  border-radius: 0.2em;
  border: 1px solid $gray-light;
  outline: none;
}

.big-form:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.button,
input[type="submit"] {
  display: inline-block;
  box-sizing: border-box;
  padding: 0.5em 1.2em;
}

.button,
.button:visited,
.button:active,
input[type="submit"] {
  border-radius: 9px;
  background-color: $brand-accent;
  color: #fff !important;
  text-decoration: none;
  border: 1px solid $brand-accent;

  transition: background-color 0.3s, color 0.3s, border-color 0.3s;

&:hover {
    background-color: #f27b14;
    border-color: #f27b14;;
  }

  &.button-alt {
    border-color: $background-color-dark;
    background-color: $background-color-dark;

    &:hover {
      background-color: darken($background-color-dark,1.6); 
      border-color: darken($background-color-dark,1.6); 
    }
  }
  &.button-outline {
    color: lighten($background-color, 20%) !important;
    border-color: lighten($background-color, 50%) !important;
    background-color: transparent;

    &:hover {
      background-color: darken($background-color-dark,1.6) !important;
      border-color: darken($background-color-dark,1.6)  !important;
      color: white !important;
    }
  }
  &.button-outline-alt {
    color: $brand-accent !important;
    border-color: $brand-accent !important;
    background-color: transparent;
  }
}

.button.hero-button {
  padding-left: $spacing-large;
  padding-right: $spacing-large;
  margin-top: $spacing-small;
  margin-bottom: $spacing-small;
  border-radius: 15px;
  font-size: $large-font-size;

  > strong {
    font-weight: $base-font-weight;
  }

  &.alt-cta {
    font-size: $large-font-size;
    margin-top: $spacing-small;
    background-color: rgba(0,0,0,0.0);
    border-color: #fff;
    margin-bottom: $spacing-large;
  }
}

.faq-wrapper {
  column-count: 2;
  column-gap: $spacing-medium;
  .faq {
    -webkit-column-break-inside: avoid;
    break-inside: avoid;
    padding-bottom: $spacing-medium;
  }
}

@import "header";

.page-content {
  flex-grow: 1;
  background-color: #fff;
  padding-top: 72px;
  //holla
  .wrapper {
    main {
      &.alt {
        section {
          background-color: #fff;
        }
        section:nth-child(even) {
          background-color: $gray-ultra-light;
        }
      }

      section {
        background-color: #fff;

        > .section-inner {
          max-width: $content-width;
          margin-left: auto;
          margin-right: auto;

          box-sizing: border-box;
          padding: $spacing-large $spacing-medium;

          a {
            color: $brand-accent;
          }
        }
      }
      section:nth-of-type(odd) {
        background-color: $gray-ultra-light;
      }
      section:only-child {
        background-color: #fff;
      }
      section.centered {
        text-align: center;
        p {
          margin-left: auto;
          margin-right: auto;
          max-width: 760px;
        }
      }

      section.no-padding {
        > .section-inner {
          padding: 0;
        }
      }

      section.dark {
        background-color: $background-color !important;
        > .section-inner {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: white;
          }
          p {
            color: rgba(255, 255, 255, 0.6);

            em {
              color: white;
            }
          }

          .button,
          .button:visited,
          .button:active,
          input[type="submit"] {
            &.button-outline {
              color: white !important;
              border-color: lighten($background-color, 50%) !important;
              background-color: transparent;

              &:hover {
                color: $background-color !important;
                background-color: lighten($background-color, 50%) !important;
                border-color: lighten($background-color, 50%) !important;
              }
            }
          }
        }
      }

      section.accent {
        background-color: $brand-accent !important;
        > .section-inner {
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            color: white;
          }
          p {
            color: white;
          }
        }
      }

      section.hero {
        min-height: 30vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        overflow: hidden;

        background-color: $background-color-light;

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-weight: $bold-font-weight;
          font-size: $xlarge-font-size;
          color: white;
        }
        p {
          font-size: $base-font-size;
          color: white;
          em {
            font-style: normal;
            font-weight: $bold-font-weight;
          }
        }

        .youtube-wrapper,
        .product-teaser {
          margin-top: $spacing-medium;
          margin-bottom: -$spacing-large;
          box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
        }
      }

      section.dark {
        background-color: $background-color-dark;
      }
    }
  }
}

$module-features-list-badge-size: 240px;

section.module-features-list-wrapper {
  background-color: lighten($background-color-dark, 10%) !important;
  width: 100%;
  ul.module-features-list {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    li.module-features-list-item {
      width: $module-features-list-badge-size;
      height: $module-features-list-badge-size;
      margin: $spacing-small;

      position: relative;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        content: "";
        display: block;
        width: $module-features-list-badge-size;
        height: $module-features-list-badge-size;
        border-radius: 50% 50%;
        background-color: $background-color-dark;

        transform: scale(0.6);
        opacity: 0;

        transition: opacity 0.3s, transform 0.4s;
      }

      &:hover {
        &:before {
          transform: scale(1);
          opacity: 1;
          transition: opacity 0.3s, transform 0.6s cubic-bezier(0, 1.6, 0.4, 1);
        }
      }

      &[data-active] {
        &:after {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;
          content: "";
          display: block;
          width: $module-features-list-badge-size;
          height: $module-features-list-badge-size;
          border-radius: 50% 50%;
          background-color: lighten($background-color-light, 8%);
        }
      }

      .module-features-list-item-content {
        position: relative;
        z-index: 5;
        width: $module-features-list-badge-size;
        height: $module-features-list-badge-size;

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        text-align: center;

        padding: $spacing-medium;
        box-sizing: border-box;

        color: white;

        &:hover {
          padding: $spacing-medium;
        }

        img {
          display: block;
          margin-bottom: $spacing-small;
        }

        h2 {
          margin: 0;
          margin-bottom: $spacing-xsmall;
          font-size: $base-font-size !important;
          color: white;
        }

        p {
          margin-bottom: 0;
          font-size: $small-font-size !important;
          color: white;
        }
      }
    }
  }
}

img.section-header-image {
  max-height: 80px;
  margin-top: $spacing-medium;
  margin-bottom: $spacing-large;
}

.related-posts {
  > section > .section-inner {
    padding-top: $spacing-medium;

    > h2 {
      text-align: center;
      margin-bottom: $spacing-large;
    }

    .related-post-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      > a {
        display: block;
        width: 50%;
        margin-bottom: $spacing-small;
        box-sizing: border-box;
        padding-right: $spacing-medium;

        article {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          justify-content: stretch;

          .related-post-thumb {
            flex: 0 0 auto;
            width: 60px;
            height: 50px;
            margin-right: $spacing-small;
            display: flex;
            align-items: center;
            justify-content: center;

            > img {
              display: block;
              max-width: 60px;
              max-height: 40px;
            }
          }

          > div {
            flex: 1 1 100%;
            color: $gray-medium;
            h2 {
              font-size: $large-font-size;
              margin-bottom: $spacing-xxsmall;
            }
            p {
            }
          }
        }
      }
    }
  }
}

.align-centre {
  text-align: center;
}

@import "footer";

@include responsive-medium() {
  .no-tablet {
    display: none;
  }

  .page-content .wrapper main {
    section {
      .section-inner {
        padding-left: $spacing-small;
        padding-right: $spacing-small;
      }
    }
  }

  footer.site-footer {
    .wrapper {
      flex-wrap: wrap;

      .menu-wrapper {
        flex: 0 1 auto;
        margin-bottom: $spacing-small;
      }
      .brand-wrapper {
        text-align: left;
        flex: 1 1 auto;
      }
    }
  }
}

@include responsive-small() {
  .page-content {
    padding-top: 50px;
  }

  .no-mobile {
    display: none;
  }

  .slicknav_menu {
    display: block;
  }

  #menu-placeholder {
    flex: 1 1 50%;
    display: block;
  }

  .cta {
    background-color: $background-color-dark;
    color: #fff;
    .wrapper {
      display: block;
      text-align: center;
      .cta-left {
        flex: none;
        margin-right: 0;
        margin-bottom: $spacing-small;
      }
      .cta-right {
        flex: none;
      }
    }
  }

  .framework-table {
    width: 100%;
    display: block;
    margin: $spacing-medium 0;
    .framework-table-column {
      display: block;
      border: none;

      padding-bottom: $spacing-small;
      margin-bottom: $spacing-small;
      border-bottom: 1px solid $gray-light;
    }
    .framework-table-column:last-child {
      border-bottom: none;
      margin-bottom: 0;
      border-bottom: 0;
    }
  }

  .faq-wrapper {
    column-count: 1;
  }

  footer.site-footer {
    .wrapper {
      .menu-wrapper {
        flex: 1 1 50%;
      }
      .brand-wrapper {
        margin-top: $spacing-medium;
      }
    }
  }

  .page-content .wrapper main section.product-teaser-container .section-inner {
    padding-bottom: $spacing-medium;
  }

  .related-posts {
    > section > .section-inner {
      .related-post-list {
        display: block;
        > a {
          width: 100%;
          padding-right: 0;
        }
      }
    }
  }
}
