//VARIABLES

//global

$hero-animation-time: 8s;
$hero-animation-time-delay: 0.2s;
$stagger-error-popups: 0.05s;

$shadow-size: 5px;
$shadow-opacity: 0.3;

$shadow: 0 4px $shadow-size rgba(black, $shadow-opacity);
$shadow-filter: drop-shadow($shadow);
$shadow-green: 0 0 $shadow-size rgba(green, $shadow-opacity);
$shadow-green-filter: drop-shadow($shadow-green);
$shadow-red: 0 0 $shadow-size rgba(red, $shadow-opacity);
$shadow-red-filter: drop-shadow($shadow-red);

//main

$main-fade-time: 5%;

//popups

$popup-fade-duration: 5%;
$popup-fade-in-time: 5%;
$popup-fade-out-time: 45%;

$popup-enter-transform: 15px;

//main popup

$popup-main-fade-in-time: 25%;
$popup-main-crossfade-time: 50%;
$popup-main-fade-out-time: 100%;

//KEYFRAMES

@keyframes hero-animation-main {
  0% {
    opacity: 0;
  }
  #{$main-fade-time} {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hero-animation-error-popups {
  #{$popup-fade-in-time} {
    opacity: 0;
    transform: translateY($popup-enter-transform);
  }
  #{$popup-fade-in-time + $popup-fade-duration} {
    opacity: 1;
    transform: none;
  }
  #{$popup-fade-out-time - $popup-fade-duration} {
    opacity: 1;
    transform: none;
  }
  #{$popup-fade-out-time} {
    opacity: 0;
    transform: translateY(15px);
  }
}

@keyframes hero-animation-popup-main-error {
  #{$popup-main-fade-in-time - $popup-fade-duration} {
    opacity: 0;
  }
  #{$popup-main-fade-in-time} {
    opacity: 1;
  }
  #{$popup-main-crossfade-time} {
    opacity: 1;
  }
  #{$popup-main-crossfade-time + $popup-fade-duration} {
    opacity: 0;
  }
}

@keyframes hero-animation-popup-main-fix {
  #{$popup-main-crossfade-time - $popup-fade-duration} {
    opacity: 0;
  }
  #{$popup-main-crossfade-time} {
    opacity: 1;
  }
  #{$popup-main-fade-out-time - $popup-fade-duration} {
    opacity: 1;
  }

}

//IMPLEMENTATION

.page-content .wrapper main {
  section {
    &.index-hero {
      .section-inner {
        .hero-image {
          img {
            will-change: transform;
            user-select: none;

            &.main {
              opacity: 0;
              animation: hero-animation-main $hero-animation-time forwards;
              filter: $shadow-filter;
            }

            &.error-popup {
              opacity: 0;
              animation: hero-animation-error-popups $hero-animation-time forwards infinite;
              filter: $shadow-red-filter;

              &.error-popup-2 {
                animation-delay: $hero-animation-time-delay + $stagger-error-popups;
              }
              &.error-popup-3 {
                animation-delay: $hero-animation-time-delay + ($stagger-error-popups * 2);
              }
            }

            &.popup-main-error {
              opacity: 0;
              animation: hero-animation-popup-main-error $hero-animation-time forwards infinite;
              filter: $shadow-red-filter;
            }

            &.popup-main-fix {
              opacity: 0;
              animation: hero-animation-popup-main-fix $hero-animation-time forwards infinite;
              filter: $shadow-green-filter;
            }
          }
        }
      }
    }
  }
}
