@import "./responsive";

ul.features {
  list-style: none;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0;

  li.feature {
    margin: $spacing-small $spacing-small * 0.5;
    flex: 0 0 auto;
    width: calc(33% - #{$spacing-small});
    padding-top: 120px;
    background-size: auto 100px;
    background-repeat: no-repeat;
    background-position: center top;

    &.bin {
      background-image: url("/assets/images/features-bin.svg");
    }
    &.centralized {
      background-image: url("/assets/images/features-centralized.svg");
    }
    &.graph {
      background-image: url("/assets/images/features-graph.svg");
    }
    &.search {
      background-image: url("/assets/images/features-search.svg");
    }
    &.stack {
      background-image: url("/assets/images/features-stack.svg");
    }
    &.user {
      background-image: url("/assets/images/features-user.svg");
    }

    p {
      margin: 0;
      padding: 0;
      margin-bottom: 1em;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.mini-features {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  .feature {
    padding-top: calc(80px + #{$spacing-small});
    flex: 0 0 auto;
    width: calc(33% - #{$spacing-small});
    text-align: center;

    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: auto 80px;

    display: flex;
    flex-direction: column;

    h2 {
      flex: 0 0 auto;
    }
    p {
      flex: 1 0 auto;
    }
    a {
      flex: 0 0 auto;
      width: auto;
      margin: auto 0;
      align-self: center;
      margin-top: $spacing-small;
    }

    &.fast {
      background-image: url("/assets/images/icon-fast.svg");
    }
    &.secure {
      background-image: url("/assets/images/icon-secure.svg");
    }
    &.light {
      background-image: url("/assets/images/icon-light.svg");
    }
  }
}

.page-content {
  .wrapper main section.product-hero {
    padding: $spacing-medium;
    .section-inner {
      align-items: center;
      padding: $spacing-small $spacing-medium;
      max-width: 1400px;
      .hero-image {
        margin-left: 0;
        width: 100%;
        height: auto;
        min-width: 0px;
        margin-right: $spacing-xlarge;
        img {
          position: relative;
          width: 100%;
          height: auto;
          box-shadow: 3px 3px 15px rgba(black, 0.3);
        }
      }
      .hero-content {
        max-width: 460px;
        .hero-content-top {
          display: flex;
          align-items: center;
          margin-bottom: $spacing-small;
          img {
            margin-right: $spacing-small;
          }
          h1 {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  section.product-sections-wrapper {
    min-height: auto !important;
    background-color: lighten($background-color-dark, 7.5%) !important;
    .section-inner {
      .product-sections {
        display: flex;
        flex-direction: row;
        .product-section {
          display: flex;
          margin: 0 $spacing-large;
          position: relative;
          a.product-section-content {
            position: relative;
            z-index: 2;
            padding: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            h2 {
              font-size: $base-font-size;
              font-weight: 500;
              color: white;
              margin: 0;
              text-align: left;
            }
            img {
              width: 30px;
              height: 30px;
              margin-bottom: 0;
              margin-right: $spacing-small;
            }
          }
          &[data-active] {
            opacity: 0.6;
            pointer-events: none;
          }
        }
      }
    }
  }

  section.module-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-height: 500px;

    .section-inner {
      display: flex;
      align-items: center;
      > img {
        width: 100%;
        max-width: 400px;
        max-height: 400px;
        margin-right: $spacing-xlarge;
      }
      .module-section-content {
        text-align: left;
        max-width: 800px;
        width: 100%;
        > h2 {
          font-size: $xlarge-font-size;
          margin-bottom: $spacing-small;
          max-width: $limit-width;
        }

        > p {
          font-size: $large-font-size;
          max-width: $limit-width;

          @include limit-width();
        }

        > a.button {
          margin-top: $spacing-medium;
          font-size: $large-font-size;
        }
      }
    }

    &:nth-of-type(odd) {
      .section-inner {
        flex-direction: row-reverse;
        > img {
          margin-right: 0;
          margin-left: $spacing-xlarge;
        }
      }
    }

    &.view {
      .section-inner {
        > h2 {
          &:before {
            background-image: url(/assets/images/module-view-color.svg);
          }
        }
      }
    }
    &.analyze {
      .section-inner {
        > h2 {
          &:before {
            background-image: url(/assets/images/module-analyze-color.svg);
          }
        }
      }
    }
    &.resolve {
      .section-inner {
        > h2 {
          &:before {
            background-image: url(/assets/images/module-resolve-color.svg);
          }
        }
      }
    }
  }
}

@include responsive-medium() {
  .page-content {
    .wrapper main section.product-hero{
      .section-inner{
        padding: 0;
        .hero-image{
          margin-right: 0;
          margin-bottom: $spacing-large;
        }
      }
    }
    section.module-section {
      &:nth-of-type(odd) {
        .section-inner {
          flex-direction: column;
          > img {
            margin-left: 0;
          }
        }
      }
      .section-inner {
        flex-direction: column;
        > img {
          margin-right: 0;
          max-width: 240px;
          max-height: 240px;
          margin-bottom: $spacing-large;
        }
      }
    }
  }
}

@include responsive-small() {
  ul.features {
    display: block;
    li.feature {
      width: 100%;
    }
  }

  .mini-features {
    display: block;

    .feature {
      width: 90%;
      margin: 0 auto;
      border-bottom: 1px solid $gray-light;
      padding-bottom: $spacing-medium;
      margin-bottom: $spacing-medium;

      a {
        margin-top: 0;
      }
      &:last-child {
        border-bottom: none;
        margin-bottom: 0;
      }
    }
  }

  .page-content {
    section.product-sections-wrapper {
      .section-inner {
        .product-sections {
          flex-direction: column;
          .product-section {
            a.product-section-content {
              display: flex;
              flex-direction: row;
              align-items: center;
              h2 {
                margin: 0;
              }
              img {
                width: 30px;
                height: 30px;
                margin-bottom: 0;
                margin-right: $spacing-small;
              }
            }
          }
        }
      }
    }
  }

  .hero.centered.module-hero-list-wrapper {
    .section-inner {
      padding: $spacing-small;
    }

    ul.module-hero-list {
      justify-content: center;
      transform: none;
      flex-direction: column;

      li.module-hero-list-item {
        flex: 0 0 auto;

        width: 100%;
        height: auto;
        margin: 0;
        margin-right: $spacing-small;
        margin-bottom: $spacing-medium;

        &:last-of-type {
          margin-bottom: 0;
        }

        a.module-hero-list-item-content {
          width: auto;
          height: auto;
          flex-direction: row;
          justify-content: flex-start;
          width: 100%;

          padding: 0;

          text-align: center;

          h2 {
            font-size: $small-font-size;
            opacity: 0.6;
            margin-bottom: 0;
          }

          p {
            display: none;
          }

          img {
            height: 32px;
            margin-bottom: 0;
            margin-right: $spacing-small;
          }
        }

        &[data-active] {
          a.module-hero-list-item-content {
            h2 {
              opacity: 1;
            }
          }
        }

        &:before,
        &:after {
          display: none !important;
        }
      }
    }
  }

  section.module-section {
    .section-inner {
      p {
        font-size: $base-font-size;
      }
      > ul {
        display: block;
      }
    }
  }
}
