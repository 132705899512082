.cta{
  background-color: $background-color-dark;
  color: #fff;
  .wrapper{
    width: 100%;
    max-width: $content-width;
    padding: $spacing-small $spacing-medium;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    h2{color: #fff; font-size: $large-font-size; font-weight: $bold-font-weight; margin: 0;}
    p{
      margin: 0; padding: 0; color: $transparent-white;

      &.large{
        font-size: $large-font-size * 0.8;
      }
    }
    a{color: #fff; font-weight: $bold-font-weight;}

    .cta-left{
      flex: 1 1 auto;
      margin-right: $spacing-medium;
    }
    .cta-right{
      flex: 0 0 auto;
    }
  }

  &.light{
    background-color: $gray-ultra-light;
    color: $gray-dark;

    h2{color: $gray-medium;}
    p{color: $body-text-color;}
    a{color: $brand-accent;}
  }
}

.more-banner{
  background-color: #fff;

  .wrapper{
    width: 100%;
    max-width: $content-width;
    padding: $spacing-medium;
    box-sizing: border-box;
    margin: 0 auto;
    text-align: center;
  }
}

footer.site-footer{
  color: #fff;
  background-color: $background-color;
  width: 100%;
  .wrapper{

    padding: $spacing-medium;

    max-width: $content-width;

    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: row;

    .menu-wrapper{
      flex: 0 0 auto;
      padding-right: $spacing-medium * 0.8;
      box-sizing: border-box;

      h2{
        color: #fff;
        font-size: $base-font-size;
        margin-bottom: 0.5em;
      }

      ul{
        list-style: none;
        margin: 0;
        margin-bottom: $spacing-small;
        padding: 0;
        font-size: $small-font-size;

        li{
          color: rgba(255,255,255,0.45);
          a{
            color: rgba(255,255,255,0.8);
          }
        }

        li.twitter{
          padding-left: 1.3em;
          background-image: url("/assets/images/twitter.svg");
          background-position: left center;
          background-size: 1.15em auto;
          background-repeat: no-repeat;
        }
      }
    }

    .brand-wrapper{
      flex: 1 0 auto;
      text-align: right;
      font-size: $small-font-size;
      color: rgba(255,255,255,0.45);
      box-sizing: border-box;

      a{
        color: rgba(255,255,255,0.8);
      }

      > img{
        height: 2.2em;
        margin-bottom: $spacing-small;
      }

      > p{
        margin-bottom: 0.2em;
      }
    }
  }
}
