$screen-size-phone: 738px;
$screen-size-tablet: 930px;


@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}
  
  
@mixin responsive-large() {
//Landscape tablet;
    @include media-query($content-width) {
        @content;
    }
}

@mixin responsive-medium() {
//Portrait tablet/landscape phone;
    @include media-query($screen-size-tablet) {
        @content;
    }
}

@mixin responsive-small() {
//Portrait phone;
    @include media-query($screen-size-phone) {
        @content;
    }
}
