section.benefits-content-wrapper{
  img{
      max-height: 375px;
      box-shadow: 3px 3px 15px rgba(0,0,0,.15);
  }   
  h2{
    padding-bottom: $spacing-medium;
  }
  h2:nth-child(n+2) {
    padding-top: $spacing-medium;
  }
}

section.benefits-video-wrapper{
  .youtube-wrapper{
      padding-bottom: 40.25%;
  }
}

.feature-flex-container{
    display: flex;
    align-items: center;
    flex-flow: row wrap;
    padding-bottom: $spacing-large;
  }

  .feature-flex-50{
    flex: calc(50% - 30px);
    padding-right: 15px;
  }

  .feature-flex-50:nth-child(even) {
    padding-right: 0;
  }

  @media (max-width: 768px) {
    .feature-flex-container {
       display: block;
    }

    .feature-flex-50 {
      padding-right: 0;
    }

    .feature-flex-50:nth-child(even) {
      padding-left: 0;
    }

  }




