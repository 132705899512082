.slicknav_btn {
	width: 120px;
	height: 50px;
	margin: 0;
	box-sizing: border-box;
	padding-left: 0;
	background: none;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	float: none;
}


.slicknav_menu {
	padding: 0;
	background: none;

	.slicknav_menutxt{
		display: none;
	}

	.slicknav_icon{
		float: left;
	}

	.slicknav_icon:before{
		position: static;
		width: auto;
		height: auto;
	}

	ul{
		margin: 0;
		margin-bottom: $spacing-medium;

		li{
			a:hover{
				background: none;
				color: #fff;
			}

			a.button{
				margin-top: $spacing-small;
				width: 120px;
				text-align: center;
				background-color: $brand-accent;
			}
		}
	}
	&:before{
		display: none;
	}

}

.slicknav_nav{
	font-size: $base-font-size;

}

.slicknav_nav .slicknav_row, .slicknav_nav a{
	margin: 0;
	padding: 10px 0;
}

.slicknav_nav a.button:hover{
	border-radius: 9px !important;
}
