.slicknav_menu {
  display: none;
}

#menu-placeholder {
  display: none;
}

header.site-header {
  color: #fff;
  background-color: $background-color-dark;
  border-bottom: 1px solid #fff;

  z-index: 99;

  position: fixed;
  left: 0;
  right: 0;
  top: 0;

  .wrapper {
    width: 100%;
    max-width: $content-width;
    padding: 0 $spacing-medium;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: stretch;

    z-index: 99;

    .site-title {
      display: flex;
      flex: 0 0 auto;
      align-items: center;
      a {
        display: block;
        width: 150px;
        height: 48px;
        background-image: url("/assets/images/loupe-white.svg");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        text-indent: -100vw;
      }
      &.active {
        background-image: url("/assets/images/nav-indicator.svg");
        background-position: center bottom;
        background-repeat: no-repeat;
      }
    }

    nav.site-nav {
      flex: 1 0 auto;

      > ul {
        display: flex;
        flex-direction: row;
        list-style: none;
        justify-content: flex-end;
        margin: 0;
        padding: 0;

        > li {
          display: flex;
          padding: $spacing-small 0;
          flex: 0 1 auto;
          align-items: center;
          justify-content: center;
          // margin-left: $spacing-small;

          &.mobile-link {
            display: none;
          }

          &.burger-home {
            display: none;
          }

          &.active {
            background-image: url("/assets/images/nav-indicator.svg");
            background-position: center bottom;
            background-repeat: no-repeat;
          }

          a {
            transition: opacity 0.3s;
          }

          a,
          a:hover,
          a:visited,
          a:active {
            padding: 0.6em 1.2rem;
            color: #fff;
            text-decoration: none;
          }
          &:hover > a {
            opacity: 0.7;
          }
          a.button {
            margin: 0 0.6em;
            padding: 0.5em 1.2em;
          }
          a.loginButton{
            background-color: rgba(0, 0, 0, 0.0); 
            border: 1px solid #fff;
          }
          a:last-child {
            margin-right: 0;
          }

          a.external {
            padding-right: 26px;
            background: url("/assets/images/share.svg") right center no-repeat;
            background-position: calc(100% - 6px) 50%;
            background-size: 14px 14px;
          }

          $module-features-list-badge-size: 240px;

          &#features {
            cursor: pointer;
            position: relative;
            #features-inner {
              position: absolute;
              left: 0;
              top: 0;
              bottom: -1px;
              right: 0;
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden;

              pointer-events: none;
              &::after {
                content: "";
                display: block;
                transition: opacity 0.3s;

                position: absolute;
                bottom: 0;
                left: 50%;
                width: 10px;
                height: 10px;
                background-color: lighten($background-color-dark, 10%) !important;

                transform: translate(-50%, 50%) rotate(45deg);

                border-top: 1px solid white;
                border-left: 1px solid white;

                opacity: 0;
              }
            }
            .features-inner {
              position: fixed;
              left: 0;
              top: 70.5px;
              right: 0;
              background-color: lighten($background-color-dark, 10%) !important;

              transition: opacity 0.3s;

              z-index: -1;

              opacity: 0;
              pointer-events: none;

              padding: $spacing-large;

              box-shadow: 0 0 15px rgba(black, 0.3);

              section.module-features-list-wrapper {
                ul.module-features-list {
                  transform: translateY(-50px);
                  transition: transform 0.3s;
                }
              }
            }
            &:hover {
              #features-inner {
                &::after {
                  opacity: 1;
                }
              }
              .features-inner {
                opacity: 1;
                pointer-events: all;
                section.module-features-list-wrapper {
                  ul.module-features-list {
                    transform: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
nav.sub-nav {
  background-color: $background-color;
  border-bottom: 1px solid #fff;

  ul {
    box-sizing: border-box;
    width: 100%;
    max-width: $content-width;
    margin: 0 auto;
    padding: 0 $spacing-medium;
    display: flex;
    flex-direction: row;

    li {
      list-style: none;
      flex: 0 1 auto;
      color: rgba(255, 255, 255, 0.45);

      a {
        display: block;
        box-sizing: border-box;
        padding: $spacing-small 0;
        margin-right: $spacing-small;
        color: white;
      }

      &.active {
        color: #fff;
      }
    }
  }
}

.viewer-banner {
  background-color: $gray-ultra-light;

  .wrapper {
    width: 100%;
    max-width: $content-width;
    padding: $spacing-small $spacing-medium;
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;

    h2 {
      font-size: $large-font-size;
      font-weight: $bold-font-weight;
      margin: 0;
    }
    p {
      margin: 0;
      padding: 0;
    }

    .viewer-banner-left {
      flex: 1 1 auto;
      margin-right: $spacing-medium;
    }
    .viewer-banner-right {
      flex: 0 0 auto;
    }
  }
}

@include responsive-medium() {
  header.site-header {
    .wrapper {
      .site-title {
        margin-right: $spacing-small;
        a {
          width: 100px;
          height: 32px;
        }
      }
      .features-inner {
        display: none;
      }
    }
  }
}

@include responsive-small() {
  nav.sub-nav {
    ul {
      padding-left: $spacing-small;
      padding-right: $spacing-small;
    }
  }

  header.site-header {
    .wrapper {
      align-items: flex-start;
      padding-left: $spacing-small;
      padding-right: $spacing-small;

      .site-title {
        flex: 0 0 auto;
        align-items: center;

        a {
          width: 75px;
          height: 50px;
        }
        &.active {
          background: none;
        }
      }

      nav.site-nav {
        flex: 1 1 50%;
        & > ul {
          display: none;
        }
      }
    }
  }

  .slicknav_menu{
    nav.slicknav_nav{
      ul{
        li{
          &.mobile-link{
            padding-left: $spacing-medium;
          }
        }
      }
    }
  }
}
