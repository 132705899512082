.technology-wrapper{
    max-width: $content-width;

    margin-left: auto;
    margin-right: auto;

    padding: $spacing-medium;
    box-sizing: border-box;

    .technology{
        display: flex;

        main.technology-main{
            flex: 1 1 0;
        }
        aside.technology-aside{
            flex: 0 0 $aside-width;
            margin-left: $spacing-medium;

            h2{
                font-size: $large-font-size;
                font-weight: $light-font-weight;
                padding-bottom: 0.5em;
                border-bottom: 1px solid $gray-light;
                margin: 0;
                margin-top: $spacing-large;
          
                &:first-child{
                  margin-top: 0;
                }
            }

            ul, li{
                margin: 0;
                padding: 0;

                list-style: none;
            }

            li{
                padding: $spacing-small 0;
                h3{
                    font-size: $base-font-size;
                    font-weight: $base-font-weight;
                    margin-bottom: 0;
                }
            }
        }
    }
}


@include responsive-large() {
    .technology-wrapper{
        .technology{
            main.technology-main{
                 width: calc(100% - #{$aside-width + $spacing-medium});
            }
        }
    }
}


@include responsive-medium() {
    .technology-wrapper{
        padding-left: $spacing-small;
        padding-right: $spacing-small;

        .technology{
            display: block;

            main.technology-main{
                 width: 100%;
            }

            aside.technology-aside{
                margin-left: 0;
            }
        }
    }
}

@include responsive-small() {
    
}