.blog-wrapper {
  display: flex;
  flex-direction: row;

  box-sizing: border-box;
  padding: $spacing-large $spacing-medium;

  max-width: $content-width;
  margin-left: auto;
  margin-right: auto;

  main.blog-main {
    flex: 1 1 auto;
  }

  aside.blog-aside {
    margin-left: $spacing-medium;
    flex: 0 0 $aside-width;

    h2 {
      font-size: $large-font-size;
      font-weight: $light-font-weight;
      padding-bottom: 0.5em;
      border-bottom: 1px solid $gray-light;
      margin: 0;
      margin-top: $spacing-large;

      &:first-child {
        margin-top: 0;
      }
    }

    h3 {
      font-size: 1em;
      font-weight: $base-font-weight;
      margin: 0;
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
      }
    }
  }

}

article.post {
  margin-bottom: $spacing-large;

  h3,
  h4,
  h5,
  h6 {
    font-size: $base-font-size;
    font-weight: $bold-font-weight;
  }

  ul {
    li {
      margin-bottom: $spacing-small;
    }
  }

  header.post-header {
    margin-bottom: $spacing-small;

    h1.post-title {
      margin-bottom: 0.3em;
      font-size: $xxlarge-font-size;
    }

    h2.post-title {
      margin-bottom: 0.3em;
      font-size: $large-font-size;
    }

    p.post-meta {
      margin-bottom: 0;
      color: $gray-medium;
    }
  }

  img {
    display: block;
    max-width: 100%;
  }

  img.image-left,
  img.image-right {
    max-width: 280px;
  }

  img.image-left {
    float: left;
    margin-right: 2em;
  }

  img.image-right {
    float: right;
    margin-left: 2em;
  }

  img.post-poster-image {
    float: left;
    width: 150px;
    height: 150px;
    margin-right: $spacing-small;
    margin-bottom: $spacing-small;
  }

  h2#by-the-numbers {
    margin-top: $spacing-medium;
    margin-bottom: 0;
    background-color: $brand-accent;
    padding: $spacing-xlarge $spacing-large;
    padding-bottom: $spacing-small;
    color: white;
    text-align: center;
    font-size: $large-font-size;

    +ul {
      margin-top: 0;
      background-color: $brand-accent;
      list-style: none;
      display: block;
      font-size: $xxlarge-font-size;
      text-align: center;
      padding: $spacing-xlarge $spacing-large;
      padding-top: 0;
      color: white;

      li {
        display: block;

        em {
          font-style: normal;
          font-weight: $bold-font-weight;
          color: white;
        }

        >ul {
          display: block;
          margin: 0;
          padding: 0;

          >li {
            display: block;
            margin: 0;
            padding: 0;
            font-size: $large-font-size;
          }
        }
      }
    }
  }

  table {
    margin: $spacing-medium 0;
    min-width: 60%;
    border: 1px solid $gray-very-light;

    thead {
      th {
        border-bottom: 1px solid $gray-very-light;
        text-align: left;
        background-color: $gray-ultra-light;
        padding: $spacing-xsmall $spacing-small;
      }
    }

    tbody {
      
      td {
        border-bottom: 1px solid $gray-very-light;
        background-color: white;
        padding: $spacing-xsmall $spacing-small;
      }

      tr:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}

section.post-wrapper {
  .post-cover {
    width: 100%;
    height: 40vw;
    max-height: 300px;
    min-height: 140px;
    background-size: cover;
    background-position: center;
  }
  .post-content {
    p { 
      margin-bottom: 25px;
    }
    h3 {
      margin-top: $spacing-large;
    }
    li {
      margin-bottom: $spacing-small;
    }
    ol {
      margin-bottom: $spacing-small;
    }

    table{
      max-width: 100%;
    }
  }
  .section-inner {
    display: flex;
    flex-direction: row;

    aside.post-aside {
      margin-left: $spacing-medium;
      flex: 0 0 $aside-width;

      .post-author {
        border-bottom: 1px solid $gray-light;
        margin-bottom: $spacing-small;

        .post-author-top {
          display: flex;
          align-items: center;
          margin-bottom: $spacing-small;

          .post-author-image {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-size: cover;
            background-position: center;
          }

          .post-author-top-inner {
            margin-left: $spacing-small;
            margin-bottom: 0;

            h3,
            p {
              margin-bottom: 0px;
            }
          }
        }

        i {
          opacity: 0.7;
        }
      }
    }

    >article.post {
      width: calc(100% - #{$aside-width + $spacing-medium * 3});
    }
  }
}

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;

  .previous,
  .next {
    display: flex;
    flex: 1 0 25%;
  }

  .previous {
    justify-content: flex-start;
  }

  .next {
    justify-content: flex-end;
  }

  .page_number {
    display: flex;
    flex: 1 0 50%;
    justify-content: center;
  }
}

main.blog-main {
  ul.blog-list {
    margin: 0;
    padding: 0;

    >li {
      padding-bottom: $spacing-medium;
      margin-bottom: $spacing-medium;
      border-bottom: 1px solid $gray-light;
      list-style: none;

      article.post {
        margin: 0;
      }
    }

    >li:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

aside.blog-aside {
  ul.blog-list {
    margin: 0;
    padding: 0;

    >li {
      padding: 1em 0;
      border-bottom: none;
      list-style: none;
    }

    >li:last-child {
      padding-bottom: 0;
      border-bottom: none;
    }
  }
}

@include responsive-medium() {
  main section.post-wrapper .section-inner {
    width: 100%;
    margin: 0;
    display: block;
    padding: $spacing-medium $spacing-small;

    aside.post-aside {
      margin: $spacing-large 0;
    }

    >article.post {
      width: 100%;
    }
  }

  .blog-wrapper {
    width: 100%;
    margin: 0;
    display: block;
    padding: $spacing-medium $spacing-small;

    aside.blog-aside {
      margin: $spacing-large 0;
    }
  }
}

@include responsive-small() {
  article.post{
  .tableMob {
     thead {
       display: none;
     }

     td {
       display: flex;
       border-bottom: none;
     }
     td::before {
       content: attr(label);
       font-weight: bold;
       width: 120px;
       min-width: 120px;
     }

     tr{
      border-bottom: 1px solid #e7eaec;
      border-top: 1px solid #e7eaec;
     }
  } 
}
}