section.author-wrapper{
    .section-inner{
        .author-info{
            margin-bottom: $spacing-large;
            display: flex;
            align-items: center;
            .author-image{
                width: 100px;
                height: 100px;
                border-radius: 50%;
                background-size: 100px;
                background-position: center;
                margin-right: $spacing-medium;
            }
            .author-info-inner{
                p{
                    margin-bottom: 0;
                }
                h1{
                    margin-bottom: 0;
                }
            }
        }
        article.post{
            margin-bottom: 0;
            border-top: 1px solid $gray-light;
            padding-top: $spacing-small;
        }
        h3{
            font-size: $base-font-size;
            padding-bottom: $spacing-small;
        }
    }
}