.flexslider{
  width: 100%;
  height: auto;

	.slides{
		padding: 0;

		img{
			width: 100%;
		}
	}
}
