.pricing-calculator{

  hr {
      border-top: 1px solid #ccc;
      border-bottom: 0px;
      border-left: 0px;
      border-right: 0px;
      margin: 20px 0px
  }

  input[type='text'],
  input[type='number']{
      box-shadow: 0 1px 5px rgba(0,0,0,0.2) inset !important;
      /* height: 23px !important; */
  }

  /* PRICING COLUMN LAYOUT */

  .page-title {
      width: 100%;
  }

  h1{
    font-size: $xlarge-font-size;
  }

  h2, h3{
    font-size: $large-font-size;
    font-weight: $bold-font-weight;
  }

  table{
    width: 100%;
  }

  .pricing-tables {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      margin-top: $spacing-small;

      &.col3{
        .pricing-column {
          flex: 0 0 auto;
          width: calc(33% - #{$spacing-small * 0.5})
        }
      }

      &.col4{
        .pricing-column {
            flex: 0 0 auto;
            width: calc(25% - #{$spacing-small * 0.5}) 
        }
      }

      &.col2{
        .pricing-column {
          flex: 0 0 auto;
          width: calc(50% - #{$spacing-small * 0.5})
        }
      }

      .pricing-column {
          box-sizing: border-box;
          text-align: center;
          box-sizing: border-box;
          box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.1);
          border: 1px solid $gray-light;

          display: flex;
          flex-direction: column;

          /* PRICING COLUMN - DARK BAR */

          .dark-bar {
              background-color: $gray-dark;
              color: #fff;
              border-bottom: 1px solid #fff;
              padding: 30px 10px;
              box-sizing: border-box;
              font-size: 1.3em;

              .message-div{
                  min-height: 150px;
              }


              .button{
                background-color: $brand-accent;
                margin-top: 10px;

                &:hover {
                    background-color: darken($brand-accent, 1.15);
                    border-color: darken($brand-accent, 1.15);
                  }
              }

          }

          .dark-bar h3 {
              color: rgba(255,255,255,0.6);
              font-size: $base-font-size;
              margin-bottom: -10px;
              font-weight: 100;
              margin-top: 0px;
          }

          .dark-bar p {
              color: rgba(255,255,255,0.6);
              font-size: 12px;
              margin: 0;

              &.volume{
                color: #fff;
                font-size: 17px;
                font-weight: 300;
              }
          }

          .dark-bar .price {
              font-size: 2.7em;
              margin-top: -10px;
          }

          .dark-bar .price .smaller {
              font-size: $base-font-size;
          }

          /* PRICING COLUMN - LIGHT BAR */

          .light-bar {
              padding: 15px 10px 20px 10px;
              box-sizing: border-box;
              font-size: 1.4em;
              background-color: $gray-ultra-light;
              font-weight: 100;
              color: #777;
              border-bottom: 1px solid $gray-light;
          }

          .light-bar .description {
              font-weight: 100;
              display: block;
              color: #777;
          }

          .light-bar .description .big {
              font-size: $large-font-size;
          }

          .light-bar .description .emphasis {
              color: $gray-medium;
          }


          .light-bar .description-small {
              font-weight: 100;
              display: block;
              font-size: $small-font-size;
          }

          ul{

              margin: 0;
              color: #999;
              padding: $spacing-small $spacing-medium;
              font-size: 0.95em;
              font-weight: 100;

              li{
                  list-style-type: none;
                  border-bottom: 1px solid $gray-light;
                  padding: 15px 0;

                  a {
                      color: #3187e5
                  }
              }
              li:last-child {
                  border-bottom: 0px;
              }

              &.pricing-features{
                background-color: $gray-ultra-light;
              }
          }

      }

      .pricing-column h1,
      .row h1,
      .black-box h1
      {
          border: none !important;
      }

      .pricing-column a {
          text-decoration: none;
      }

      .pricing-column h2 {
          font-size: $base-font-size;
          font-weight: $light-font-weight;
          color: white;
          padding: 0px 20px
      }

      .price-tier-title {
        padding-bottom: 20px;
        font-size: $xlarge-font-size !important;
        font-weight: $base-font-weight !important;
    }
  }

  .button, button {
    display: block;
    box-sizing: border-box;
    background-color: $background-color;
    font-size: $base-font-size;
    border: none;
    width: calc(100% - #{$spacing-medium});
    margin: 0 auto;
  }

  a.button.active, .hs-button, button {
      background-color: $brand-accent;
      font-weight: $bold-font-weight;
  }



  .calculator-wrapper{
    .calculator {
        display: flex;

        .left-col {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;

            .content:last-child{
              flex-grow: 1;
            }
        }

        .right-col {
          margin-left: $spacing-small;
          flex: 0 0 280px;
        }

        .content {
            padding: 20px;
            box-sizing: border-box;
            background: #fff;
            color: #888;
            margin-bottom: $spacing-small;
        }
    }
  }



  .pricing-col{
      padding-top: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
  }

  .left-col .black-box {
      background-color: #4d4d4d;
      padding: 15px;
      box-sizing: border-box;
      color: #bbb;
      font-size: 0.7em;
      line-height: 200%;
      margin-bottom: 20px;
      margin-top: 20px;
  }

  .left-col .black-box .smaller {
      color: #d76e11;
      font-size: $small-font-size;
  }

  .left-col .black-box h2 {
      color: #ffffff;
      font-size: 1.2em;
      text-align: left;
      margin-bottom: 0px;
  }

  // h1 {
  //     margin-bottom: 40px;
  //     margin-top: 10px;
  // }

  h2.title {
      background-color: $gray-dark;
      display: block;
      color: #ffffff;
      padding: 15px 20px;
      font-size: $base-font-size;
      margin-bottom: 0px;
  }

  .left-col .question {
      display: block;
      margin-bottom: 20px;
      margin-top: 20px;
      clear: both;
  }

  .left-col .caption {
      font-size: 0.8em;
      margin-bottom: 0px;
  }

  .left-col .estimate-total {
      float: left;
      padding-right: 20px;
      padding-top: 10px;
      font-size: 2.5em;
      color: #ffffff;
  }

  .left-col .small-link {
      float: right;
      font-size: 0.7em;
  }

  .left-col input {
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 10px;
      text-align: center;
      width: 50px;
      margin-right: 10px;
  }

  .left-col #fader {
      width: 100%;
      box-sizing: border-box;
  }

  input[type='checkbox'] {
      display: none;
  }

  input[type='checkbox'] + label:before {
      font-family: FontAwesome;
      height: 20px;
      width: 20px;
      content: '';
      position: absolute;
      left: -60px;
      margin-top: -5px;
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 10px;
      text-align: center;
      box-shadow: 0 1px 5px rgba(0,0,0,0.2) inset !important;
      cursor: pointer;
  }

  input[type='checkbox'] + label {
      position: relative;
      margin-left: 60px;
      cursor: pointer;
  }

  input[type='checkbox']:checked + label:before {
      font-family: FontAwesome;
      content: '\f00c';
      font-size: 24px;
      line-height: 90%
  }

  .data-logging-box {
      background: #efefef;
      padding: 0px 20px 30px 20px;
      font-size: 1em;
  }

  .data-logging-box table tr {
      border-bottom: 1px solid #ccc;
      line-height: 250%
  }

  .data-logging-box table tr:first-child {
      border-bottom: 0px;
      line-height: 150%
  }

  .data-logging-box table tr:last-child {
      border-bottom: 0px;
  }

  input[type='radio'] {
      display: none;
      cursor: pointer;
  }

  input[type='radio'] + label:before {
      font-family: FontAwesome;
      height: 20px;
      width: 50px;
      content: '\f068';
      position: absolute;
      margin-top: -10px;
      padding: 10px;
      text-align: center;
      cursor: pointer;
  }

  input[type='radio'] + label {
      position: relative;
      margin-right: 75px;
      cursor: pointer;
  }

  input[type='radio']:checked + label:before {
      font-family: FontAwesome;
      content: '\f00c';
      color: #f17d19;
      font-size: 24px;
      line-height: 130%;
      margin-top: -5px;
      cursor: pointer;
  }

  .pricing-summary {
      line-height: normal;
      width: 100%;
  }

  .pricing-summary tr {
      border-bottom: 1px solid #ccc;
      background: white;
  }

  .pricing-summary tr:last-of-type{
      border-bottom: none !important;
  }

  .pricing-summary tr td{
      padding: 0px;
      padding-top: 15px;
      padding-bottom: 15px;
  }

  .pricing-summary tr td:first-of-type{
      padding-left: 15px;
  }

  .pricing-summary tr td:last-of-type{
      padding-right: 15px;
  }

  .pricing-summary tr:last-child {
      border-bottom: 0px;
  }

  .pricing-summary tr td:last-child {
      text-align: right;
  }

  .pricing-summary .smaller {
      font-size: $small-font-size;
      color: #bbb;
      line-height: normal;
  }

  .total-box {
      background: $gray-light;
      padding: $spacing-medium $spacing-small;
      text-align: center;

      h2 {
          margin-bottom: 0px;
          font-weight: normal;
          font-size: 1.2em;
      }

      h3 {
          margin-bottom: 0px;
          font-size: 4em;
          font-weight: normal;
      }

      h3 .smaller {
          font-size: 0.5em;
      }

      .warning{
        color: #dc143c;
        margin-bottom: 10px;
        font-size: $small-font-size;
      }

      .button {
          background-color: $gray-light;
          font-size: 1.5em;
          width: 225px;
          color: $gray-medium;
          padding: 12px 10px;
          margin-bottom: 20px;
      }



  }




  .right-col .content {
      margin-bottom: 0px
  }

  .right-col .black-box {
      background-color: #fff;
      padding: $spacing-medium $spacing-small;
      box-sizing: border-box;
      text-align: center;
  }

  .right-col .black-box h1 {
      margin-bottom: 10px;
      color: #bbb;
      font-size: 1.5em;
      border-bottom: 0px;
      padding: 0px;
  }

  .right-col .black-box h2 {
      font-size: 1.8em;
      margin-top: -10px;
      color: #f17d19;
      margin-bottom: 10px;
  }

  .right-col .black-box p {
      font-size: 1.3em;
      color: #ffffff;
      line-height: 125%
  }

  .right-col .button {
      font-size: $large-font-size;
      font-weight: $bold-font-weight;
      background-color: $brand-accent;
      margin-bottom: 0.6em;
  }

  .faq h3 {
      text-align: center;
      font-size: 1.3em;
      color: #999;
      font-weight: normal;
      margin-bottom: 30px;
  }

  .faq h1 {
      text-align: center;
      margin-bottom: 30px;
  }

  .faq h2 {
      text-align: left;
  }

  .faq p {
      color: #999;
  }

  .floated-column {
      float: left;
      width: 50%;
      padding: 0px 30px;
      box-sizing: border-box;
      text-align: left;
  }

  .header-link{
      float: right;
      font-size: 10pt;
      position: relative;
      top: 5px;
      color: #f17d19 !important;
  }

  .header-link i{
      margin-right: 3px;
  }

  .calculate-button{
      text-align: center;
      cursor: pointer;
      width: 540px !important;
      display: inline-block;
  }


  .url-box{
      border-radius: 5px;
      border: 1px solid #ccc;
      padding: 10px;
      text-align: center;
      width: 50px;
      margin-top: 10px;
      box-sizing: border-box;
  }

  .slider-background{
      height: 15px;
      width: calc(100% - 40px);
      position: absolute;
      margin-top: 20px;
      background: #e5e5e5;
      background-color: $gray-medium;
      border-radius: 30px;
  }

  input[type=range] {
      background: none !important;
      /*removes default webkit styles*/
      -webkit-appearance: none !important;
      /*fix for FF unable to apply focus style bug */
      border: 1px solid transparent;
      /*required for proper track sizing in FF*/
      width: 100%;
      position: relative;
      z-index: 10;
      padding: 0px;
      padding-top: 0px;
      height: 52px;
  }

  input[type=range]::-webkit-slider-runnable-track {
      height: 25px;
      background: none !important;
      border: none;
  }

  input[type=range]::-webkit-slider-thumb {
      -webkit-appearance: none;
      border: none;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: $gray-ultra-light;
      border: 1px solid $gray-light;
      position: relative;
  }

  input[type=range]:focus {
      outline: none;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
      background: transparent;
  }

  input[type=range]::-moz-range-track {
      width: 300px;
      height: 25px;
      background: transparent;
      border: none;
      border-radius: 3px;
  }

  input[type=range]::-moz-range-thumb {
      border: none;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: $gray-ultra-light;
      border: 1px solid $gray-light;
      position: relative;
  }

  /*hide the outline behind the border*/
  input[type=range]:-moz-focusring {
      outline: 1px solid transparent;
      outline-offset: -1px;
  }

  input[type=range]::-ms-track {
      width: 300px;
      height: 25px;
      background: transparent;
      border: none;
      border-radius: 10px;
      /*remove default tick marks*/
      color: transparent;
  }

  input[type=range]::-ms-fill-lower {
      outline: none;
      background: transparent;
      border-radius: 10px 0 0 10px;
  }

  input[type=range]::-ms-thumb {
      border: none;
      height: 25px;
      width: 25px;
      border-radius: 50%;
      background-color: $gray-ultra-light;
      border: 1px solid $gray-light;
      position: relative;
  }

  input[type=range]:focus::-ms-track {
      background: transparent;
  }

  input[type=range]:focus::-ms-fill-lower {
      background: transparent;
  }



  /* RESPONSIVE MEDIA QUERIES */

  @media only screen and (max-width:1000px){
      .row {min-width: inherit; max-width: inherit}
      .pricing-column h1 {font-size:1.7em}
      .pricing-column h2 {font-size:1em}
      .dark-bar .price {font-size: $large-font-size}
  }

  @media only screen and (max-width:720px){
      .pricing-column {width:100%;}
      .floated-column {width:100%;}
      .calculator{ display: none; }
  }


  .switch-row{
      background: #efa364 !important;
      color: white;
      text-align: center;
      font-size: 10pt;
  }

  .switch-row *{
      text-align: center !important;
  }

  .slider-fill{
      height: 15px;
      background-color: $brand-accent;
      position: absolute;
      top: 40px;
      border-radius: 50px 0 0 50px;
  }

  .pricing-bottom, .calc-bottom{
      width: 100%;
      height: 1px;
      background-color: transparent;
  }

  .slider-ticks{
      box-sizing: border-box;
      width: 100%;
      padding-left: 14px;
      padding-right: 8px;
      height: 40px;
  }

  .slider-tick{
      box-sizing: border-box;
      display: inline-block;
      width: 9%;
      height: 12px;
      border-left: 1px solid #efefef;
      float: left;
      position: relative;
      top: -20px;
  }

  .slider-tick:after{
      font-size: $base-font-size;
      font-weight: 200;
      content: attr(title);
      position: relative;
      top: 13px;
      left: -10px;
  }

  .slider-tick:last-of-type{
      border-right: 1px solid #efefef;
  }

  .slider-tick:nth-of-type(10):after{
      left: -10px;
  }

  .slider-tick:last-of-type:after{
      left: -30px;
  }

  .slider-tick:last-of-type:before{
      content: '12';
      position: relative;
      top: 13px;
      left: 35px;
      font-size: $base-font-size;
      font-weight: 200;
  }

  .overlay {
      background: rgba(0,0,0,0.7);
      height: 100%;
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1000;
      display: none;
  }

  .dialog {
      box-shadow: 0 0 25px rgba(0,0,0,0.4);
      padding: 20px;
      height: 430px;
      width: 600px;
      background-color: white;
      margin: 0px auto;
      margin-top: 200px;
      display: none;
      transition: transform 0.6s;
      transform: scale(1.1);
      position: relative;
  }

  .overlay2 {
      background: rgba(0,0,0,0.7);
      height: 100%;
      width: 100%;
      position: fixed;
      left: 0;
      top: 0;
      z-index: 1000;
      display: none;
  }

  .dialog2 {
      box-shadow: 0 0 25px rgba(0,0,0,0.4);
      padding: 20px;
      height: 430px;
      width: 600px;
      background-color: white;
      margin: 0px auto;
      margin-top: 200px;
      display: none;
      transition: transform 0.6s;
      transform: scale(1.1);
      position: relative;
  }

  .close-dialog-button {
      position: absolute;
      top: -45px;
      right: 0px;
      z-index: 1000;
  }

  .close-dialog-button i {
      font-size: 28pt;
      cursor: pointer;
      color: white;
  }

  .dialog-header {
      position: absolute;
      top: -45px;
      left: 0px;
      color: white;
      font-size: 20pt;
      font-weight: bold;
  }

  .contact-col{
      padding: 15px;
      padding-bottom: 5px;
      text-align: center;
  }

  /* HUBSPOT OVERRIDES */

  .hs-button{
      height: 22px !important;
      width: 140px !important;
  }

  .hs-form .actions{
      margin-left: -20px !important;
  }

  .hs-form-field, .input{
      box-sizing: border-box;
  }

  .hs-form .hs-input, .hs-form textarea.hs-input{
      width: 98% !important;
      max-width: none !important;
  }

  .hsformerror{
      margin-top: -6px !important;
  }

}

.sales-cta{
  text-align: center;
  h2{
    margin-bottom: 0.1em;
  }
  p{
    margin: 0;
  }
}


@include responsive-medium() {
.pricing-calculator{
    .pricing-tables{
      display: block;

      .pricing-column{
        width: 100% !important;
        margin-bottom: $spacing-medium;
      }
      .pricing-column:last-child{
        margin-bottom: 0;
      }
    }
    .calculator-wrapper{
      .calculator{
        display: block;

        .right-col{
          margin-left: 0;
        }
      }
    }
  }
}

@include responsive-small() {
    
}