@charset "utf-8";


//Accent Colors
$brand-accent: #F27E1A;
$resolve-accent: #1AD85E;
$monitor-accent: #0AC0EA;
$analyze-accent: #9946E8;

$base-font-family: 'Open Sans', sans-serif;;

$base-font-size:   17px;
$small-font-size:   $base-font-size * 0.85;
$large-font-size:   $base-font-size * 1.2;
$xlarge-font-size:  $base-font-size * 1.8;
$xxlarge-font-size: $base-font-size * 2.5;

$light-font-weight: 300;
$base-font-weight: 400;
$bold-font-weight: 600;

$base-line-height: 1.65;

$spacing-medium: 30px;
$spacing-small: $spacing-medium * 0.5;
$spacing-xsmall: $spacing-small * 0.5;
$spacing-xxsmall: $spacing-xsmall * 0.5;
$spacing-large: $spacing-medium * 1.5;
$spacing-xlarge: $spacing-medium * 2.5;

$background-color-light: #3e4b5c;
$background-color: #3e4b5c;
$background-color-dark: #2b3643;


$gray-dark: #3c444c;
$gray-medium-dark: lighten($gray-dark, 10%);
$gray-medium: lighten($gray-dark, 20%);
$gray-light: lighten($gray-dark, 60%);
$gray-very-light: lighten($gray-dark, 65%);
$gray-ultra-light: lighten($gray-dark, 70%);

// $grey-medium:      #828282;
// $grey-light:       lighten($grey-medium, 40%);
// $grey-dark:        darken($grey-medium, 25%);
// $grey-ultra-light: #f3f5f7;

$body-text-color: $gray-medium-dark;
$heading-text-color: $gray-dark;

$transparent-white: transparentize(#fff, 0.4);

// Width of the content area
$content-width: 1200px;

$aside-width: 300px;

$limit-width: 680px;



@mixin limit-width() {
  max-width: $limit-width;
  margin-left: auto;
  margin-right: auto;
}

.limit-width{
  @include limit-width();
}


// Import partials from `sass_dir` (defaults to `_sass`)
@import
  "normalize",
  "theme",
  "home",
  "syntax-highlighting",
  "features",
  "blog",
  "benefits",
  "author",
  "pricing",
  "technology",
  "comparison-table",
  "slicknav-overrides",
  "flexslider",
  "flexslider-overrides",
  "benefits"
;
