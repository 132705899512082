table.comparison-table{
  width: 100%;
  margin: $spacing-medium auto;
  max-width: $content-width * 0.67;

  tr,td,th,tbody,thead{
    display: block;
  }

  tr{
    display: flex;
    flex-direction: row;
  }
 td, th{
    font-weight: $base-font-weight;
    flex: 0 0 20%;
    height: 70px;
    background: rgba(255,255,255,0.05);
    margin: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
 }

 th{
   color: #fff;
 }

 thead > tr > th:first-child{
   background: none;
 }

 td:first-child, th:first-child{
    flex: 1 1 auto;
    justify-content: flex-start;
    padding: 0 $spacing-small;
    color: #fff;
 }

 td.yes{
   background-image: url("/assets/images/green-tick.svg");
   background-repeat: no-repeat;
   background-position: center center;
   background-size: auto 24px;
   > span{
     display: none;
   }
 }
 td.no{
   > span{
     display: none;
   }
 }
 /* RESPONSIVE MEDIA QUERIES */
 @media only screen and (max-width:414px){
  td, th{
    height: 90px;
  }
} 

}
